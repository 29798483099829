export default {
  "header": "qNt",
  "btn-preset": "qNM btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qNP",
  "statements-table": "qNl",
  "no-result": "qNX",
  "lottie-illustration": "qNC",
  "wrapper": "qNk",
  "access-denied": "qNJ",
  "access-denied-illustration": "qNs",
  "application-card": "qNF",
  "presets-wrapper": "qNg",
  "error-wrapper": "qND",
  "error-container": "qNV",
  "error-illustration": "qNL mb-32",
  "invoicing-info": "qNT"
};
