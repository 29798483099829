export default {
  "container": "qbn",
  "form-container": "qbi",
  "title": "qbA",
  "title-border": "qbY",
  "tabs-container": "qbh",
  "preview-container": "qbf",
  "email-preview-container": "qbK",
  "close-button": "qbG btn btn--icon-only btn--tertiary btn--large"
};
