export default {
  "container": "ZSd",
  "security-method": "ZSI",
  "icon": "ZSt",
  "body": "ZSM",
  "status": "ZSP body-2",
  "device": "ZSl",
  "device-icon": "ZSX",
  "subtitle": "ZSC body-2",
  "current": "ZSk"
};
