export default {
  "dropdown-icon": "ZqT",
  "dropdown": "Zqz",
  "download-button": "Zqa",
  "connections-banner": "ZqH",
  "recommendation-cards": "ZqO",
  "wrapper-with-cards": "Zqm",
  "transactions-wrapper": "Zqp",
  "separator": "Zqx",
  "with-transaction": "Zqw",
  "annual-billing-banner": "ZqN",
  "mt-132": "Zqv",
  "transactions-sidebar": "Zqo",
  "empty-state-wrapper": "Zqn"
};
