export default {
  "container": "qjK",
  "wrapper": "qjG",
  "grey-header-block": "qjr",
  "connect-app-header": "qjb",
  "connect-app-header-link": "qjy",
  "connect-app-header-content": "qjU",
  "connect-app-header-content-default": "qjj",
  "connect-app-header-content-left": "qjW",
  "button-block": "qju",
  "connect-app-body": "qWS",
  "connect-app-body-left-content": "qWc",
  "connect-app-body-sidebar": "qWq"
};
