export default {
  "container": "qbb",
  "form-container": "qby",
  "preview-container": "qbU",
  "credit-note-preview-container": "qbj",
  "credit-note-preview": "qbW",
  "content": "qbu",
  "title": "qyS",
  "tabs-container": "qyc",
  "close-button": "qyq btn btn--icon-only btn--tertiary btn--large",
  "header": "qyZ",
  "without-tabs": "qyR"
};
