export default {
  "details": "qKO",
  "header": "qKm",
  "avatar": "qKp mr-16",
  "header-beneficiary": "qKx",
  "header-beneficiary-activity": "qKw",
  "header-beneficiary-details": "qKN",
  "header-beneficiary-details-recurring": "qKv",
  "infos": "qKo",
  "infos-title": "qKn",
  "infos-empty": "qKi",
  "infos-list": "qKA",
  "infos-list-item": "qKY",
  "infos-list-item-left": "qKh",
  "infos-list-item-right": "qKf",
  "infos-list-item-attachment": "qKK",
  "infos-transactions": "qKG",
  "actions": "qKr"
};
