export default {
  "container": "qhh",
  "compact-header": "qhf",
  "visible": "qhK",
  "page-container": "qhG",
  "main": "qhr",
  "tabs": "qhb",
  "tabs-nav": "qhy",
  "tabs-panels": "qhU",
  "tabs-panel": "qhj body-2",
  "sidebar": "qhW",
  "prismic-content": "qhu"
};
