export default {
  "header": "qjz",
  "content": "qja",
  "read-only": "qjH",
  "document-collection-banner": "qjO",
  "submit-banner": "qjm",
  "review-banner": "qjp",
  "disclaimer": "qjx",
  "title": "qjw",
  "card-container": "qjN",
  "representatives-section": "qjv",
  "disabled-href": "qjo"
};
