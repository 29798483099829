export default {
  "container": "qAw",
  "page-wrapper": "qAN",
  "header": "qAv",
  "back-button-wrapper": "qAo",
  "header-inner": "qAn",
  "header-main-wrapper": "qAi",
  "header-main": "qAA",
  "header-right": "qAY",
  "content-wrapper": "qAh",
  "content": "qAf",
  "content-body": "qAK",
  "sidebar": "qAG"
};
