export default {
  "container": "qyy",
  "form-container": "qyU",
  "preview-container": "qyj",
  "pdf-preview-container": "qyW",
  "content": "qyu",
  "title": "qUS",
  "tabs-container": "qUc",
  "close-button": "qUq btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qUZ",
  "header": "qUR",
  "without-tabs": "qUQ"
};
