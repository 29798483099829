export default {
  "mt-16": "qjP",
  "title": "qjl",
  "avatar": "qjX",
  "edit-organization-form": "qjC",
  "dropdown-content": "qjk",
  "city-block": "qjJ",
  "zip-code-input": "qjs",
  "cancel-button": "qjF",
  "error-message": "qjg",
  "nature-of-operations": "qjD",
  "error": "qjV",
  "legal-code": "qjL",
  "activity-type": "qjT"
};
