export default {
  "page-wrapper": "ZcH",
  "content-wrapper": "ZcO",
  "content": "Zcm",
  "period-field": "Zcp",
  "format-options": "Zcx",
  "format-option": "Zcw",
  "format-radio": "ZcN",
  "format-description": "Zcv body-2"
};
