export default {
  "container": "ZSi",
  "header-cell": "ZSA caption-bold",
  "col-8": "ZSY",
  "table": "ZSh",
  "cell": "ZSf body-2",
  "cell-content": "ZSK",
  "row": "ZSG",
  "empty": "ZSr",
  "quick-actions": "ZSb",
  "card-infos": "ZSy",
  "card-digits": "ZSU",
  "empty-state": "ZSj"
};
