export default {
  "vat-number": "qua body-2",
  "banner": "quH",
  "container": "quO",
  "organization-profile": "qum",
  "input": "qup",
  "medium-input": "qux medium",
  "small-input": "quw small",
  "actions": "quN",
  "company-info": "quv",
  "company-info-section": "quo",
  "company-address-section": "qun",
  "company-country": "qui",
  "company-billing-email": "quA",
  "company-address": "quY"
};
