export default {
  "container": "qyt",
  "form-container": "qyM",
  "preview-container": "qyP",
  "pdf-preview-container": "qyl",
  "content": "qyX",
  "title": "qyC",
  "tabs-container": "qyk",
  "close-button": "qyJ btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qys",
  "header": "qyF",
  "without-tabs": "qyg"
};
