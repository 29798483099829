export default {
  "page-container": "qfr",
  "form": "qfb",
  "close-button": "qfy btn btn--icon-only btn--tertiary btn--large",
  "preview": "qfU",
  "preview-padding": "qfj",
  "title": "qfW",
  "header": "qfu",
  "without-tabs": "qKS",
  "form-section": "qKc",
  "form-footer": "qKq",
  "disclaimer-text": "qKZ"
};
