export default {
  "container": "qWL",
  "wrapper": "qWT",
  "grey-header-block": "qWz",
  "connect-app-header": "qWa",
  "connect-app-header-link": "qWH",
  "connect-app-header-content": "qWO",
  "connect-app-header-content-left": "qWm",
  "application-avatar": "qWp",
  "connect-app-header-cta": "qWx",
  "connect-app-header-meta": "qWw",
  "connect-app-content": "qWN",
  "connect-app-description": "qWv",
  "title-wrapper": "qWo",
  "title": "qWn title-1",
  "tag-line": "qWi body-2",
  "connect-app-body": "qWA"
};
