export default {
  "member-details": "qYX",
  "header": "qYC",
  "header-img": "qYk",
  "invited-persona": "qYJ",
  "revoked-persona": "qYs",
  "invited-icon": "qYF",
  "revoked-icon": "qYg",
  "dropdown": "qYD",
  "header-body": "qYV",
  "name": "qYL title-3",
  "email": "qYT caption-bold",
  "actions": "qYz body-2",
  "actions-cards": "qYa",
  "actions-transactions": "qYH",
  "body": "qYO",
  "expense-permissions-section": "qYm",
  "body-title": "qYp title-4",
  "body-attr": "qYx",
  "body-label": "qYw",
  "attr-buttons": "qYN",
  "body-role": "qYv small",
  "details-actions": "qYo",
  "actions-item": "qYn",
  "disclaimer": "qYi",
  "permissions": "qYA",
  "permission": "qYY",
  "permission-label": "qYh",
  "permission-check": "qYf",
  "permission-missing": "qYK",
  "editable-section": "qYG"
};
