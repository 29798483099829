export default {
  "container": "qUe",
  "form-container": "qUB",
  "title": "qUE",
  "title-border": "qUd",
  "tabs-container": "qUI",
  "preview-container": "qUt",
  "email-preview-container": "qUM",
  "close-button": "qUP btn btn--icon-only btn--tertiary btn--large"
};
