export default {
  "multi-transfer": "ZqG",
  "container": "Zqr",
  "title": "Zqb",
  "subtitle": "Zqy",
  "form": "ZqU",
  "form-title": "Zqj",
  "files-buttons": "ZqW",
  "files-buttons-item": "Zqu",
  "selected": "ZZS"
};
