export default {
  "supplier-invoices-page-header": "Zco",
  "filters-container": "Zcn",
  "filters": "Zci",
  "bulk-actions": "ZcA",
  "item-count": "ZcY",
  "higher-index": "Zch",
  "title-wrapper": "Zcf",
  "title": "ZcK",
  "subtitle": "ZcG",
  "table-container": "Zcr",
  "file-dropzone": "Zcb",
  "dropzone-visible": "Zcy",
  "header-actions": "ZcU",
  "header-wrapper": "Zcj"
};
