export default {
  "mandates": "qKb",
  "mandates-empty": "qKy",
  "header": "qKU",
  "header-empty": "qKj",
  "header-content": "qKW",
  "search": "qKu",
  "search-bar": "qGS",
  "search-spinner": "qGc",
  "body": "qGq",
  "isEmpty": "qGZ",
  "left-section": "qGR",
  "mandates-list": "qGQ",
  "mandate-suspended": "qGe",
  "list-title": "qGB caption-bold",
  "list-empty": "qGE",
  "empty-illustration": "qGd",
  "empty-title": "qGI title-3",
  "empty-description": "qGt body-2",
  "body-details": "qGM",
  "l-app-content__page-header": "qGP",
  "pagination-footer": "qGl"
};
