export default {
  "body": "qGX",
  "members": "qGC",
  "members-results": "qGk",
  "members-list": "qGJ",
  "members-title": "qGs caption-bold",
  "member": "qGF",
  "empty-illustration": "qGg",
  "empty-title": "qGD title-3",
  "empty-description": "qGV body-2",
  "invitable-members": "qGL",
  "details": "qGT",
  "pagination-footer": "qGz"
};
