export default {
  "page-container": "qfO",
  "form": "qfm",
  "close-button": "qfp btn btn--icon-only btn--tertiary btn--large",
  "preview": "qfx",
  "preview-padding": "qfw",
  "title": "qfN",
  "header": "qfv",
  "without-tabs": "qfo",
  "form-section": "qfn",
  "form-footer": "qfi",
  "disclaimer-text": "qfA"
};
