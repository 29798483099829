export default {
  "page-header": "ZZX",
  "header-breadcrumb": "ZZC",
  "tabs-container": "ZZk",
  "bottom-border": "ZZJ",
  "tabs-separator": "ZZs",
  "previous-request": "ZZF",
  "body-link": "ZZg",
  "border-top": "ZZD"
};
