export default {
  "splash-landing": "qoH",
  "container": "qoO",
  "options": "qom",
  "physical": "qop",
  "card-label": "qox",
  "physical-cta-container": "qow",
  "physical-cta": "qoN",
  "coming-soon": "qov"
};
