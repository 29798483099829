export default {
  "container": "qWK",
  "content": "qWG",
  "setup-box": "qWr",
  "setup-wizard": "qWb",
  "setup-success": "qWy",
  "setup-success-lottie": "qWU",
  "setup-close-btn": "qWj",
  "tray-connection": "qWW",
  "close-button": "qWu"
};
