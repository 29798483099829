export default {
  "bank-accounts": "qUy l-app-content__wrapper",
  "header-wrapper": "qUU",
  "scrolled-top": "qUj",
  "header": "qUW l-app-content__page-header",
  "create-account-button": "qUu btn btn--primary",
  "main": "qjS",
  "accounts": "qjc",
  "subtitle-container": "qjq",
  "subtitle": "qjZ title-3 mb-16",
  "amount": "qjR body-2",
  "list--cashbeeSavings": "qjQ"
};
