export default {
  "page-wrapper": "qnA",
  "container": "qnY",
  "spinner-container": "qnh",
  "left-panel": "qnf",
  "form": "qnK",
  "left-content": "qnG",
  "form-footer": "qnr",
  "delete-button": "qnb",
  "header": "qny",
  "preview-container": "qnU",
  "email-preview": "qnj",
  "close-button": "qnW btn btn--icon-only btn--tertiary btn--large"
};
