export default {
  "wrapper": "qKR",
  "header": "qKQ",
  "main": "qKe",
  "frequency": "qKB body-1",
  "radiogroup": "qKE",
  "divider": "qKd",
  "option-wrapper": "qKI",
  "option-details-wrapper": "qKt",
  "option-label": "qKM",
  "option-name": "qKP",
  "option-details-text": "qKl",
  "spinner-wrapper": "qKX",
  "spinner": "qKC"
};
