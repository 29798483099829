export default {
  "container": "qrC",
  "content": "qrk",
  "logos": "qrJ",
  "logo": "qrs",
  "animation": "qrF",
  "title": "qrg title-2 mb-32",
  "avatar": "qrD",
  "dropdown": "qrV",
  "disabled": "qrL",
  "organization": "qrT",
  "actions": "qrz"
};
