export default {
  "accounts": "qNj",
  "header-subtitle": "qNW title-4",
  "subtitle-container": "qNu",
  "subtitle": "qvS title-3 mb-16",
  "amount": "qvc body-2",
  "list--cashbeeSavings": "qvq",
  "connections-banner": "qvZ",
  "list": "qvR",
  "icon": "qvQ",
  "badge": "qve",
  "discover-tile": "qvB"
};
