export default {
  "wrapper": "qul",
  "integrations": "quX",
  "description": "quC body-2",
  "api": "quk flex flex-column",
  "api-container": "quJ flex large",
  "api-input": "qus large",
  "api-btn": "quF",
  "key-input": "qug"
};
