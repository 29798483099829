export default {
  "signin": "Zcl",
  "form": "ZcX",
  "illustration": "ZcC",
  "purple": "Zck",
  "mint": "ZcJ",
  "mustard": "Zcs",
  "peach": "ZcF",
  "form-wrapper": "Zcg",
  "header-logo": "ZcD",
  "links": "ZcV",
  "no-account-label": "ZcL",
  "spinner-container": "ZcT",
  "main-image-container": "Zcz",
  "main-image": "Zca"
};
