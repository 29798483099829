export default {
  "main-container": "qoK",
  "main": "qoG",
  "main-wrapper": "qor",
  "close-button": "qob",
  "active-block": "qoy",
  "title": "qoU",
  "list-options": "qoj",
  "card": "qoW",
  "card-container": "qou",
  "card-image": "qnS",
  "card-tiles": "qnc"
};
