import Controller from '@ember/controller';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import {
  ERROR_TOAST_IGNORE_HTTP_STATUSES,
  SENTRY_IGNORE_HTTP_STATUSES,
} from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
import scrollIntoView from 'qonto/utils/scroll-into-view';
import { sortByKey } from 'qonto/utils/sort-by-keys';

export default class InvoiceSubscriptionsNewController extends Controller {
  disclaimerInline = Disclaimer.Inline;

  @service modals;
  @service segment;
  @service store;
  @service organizationManager;
  @service intl;
  @service sentry;
  @service toastFlashMessages;
  @service router;
  @service directDebitCollectionsManager;

  @reads('organizationManager.organization') organization;

  queryParams = ['customerId', 'origin'];

  @tracked customerId = null;
  @tracked origin = null;
  @tracked isMandatesLoadingError = false;

  get logo() {
    return this.organization.get('isDefaultAvatar') === false ? this.organization.picture : null;
  }

  get customers() {
    if (variation('feature--boolean-client-hub')) {
      let clients = this.store
        .peekAll('client-hub')
        .filter(customer => {
          return (
            customer.belongsTo('organization').id() === this.organizationManager.organization.id &&
            !customer.isNew
          );
        })
        .sort(sortByKey('name'));

      //This is to be cleaned up with the FF above, meanwhile customers will be used as fallback
      if (clients.length) {
        return clients;
      }
    }

    return this.store
      .peekAll('customer')
      .filter(customer => {
        return (
          customer.belongsTo('organization').id() === this.organizationManager.organization.id &&
          !customer.isNew
        );
      })
      .sort(sortByKey('name'));
  }

  get isItalianOrganization() {
    return this.organization.legalCountry === 'IT';
  }

  get isGermanOrganization() {
    return this.organization.legalCountry === 'DE';
  }

  get emailDetailsErrorMessage() {
    // We're dealing with the Ember-Data Error class
    // eslint-disable-next-line ember/no-array-prototype-extensions
    let hasError = this.model.subscription.errors.any(({ attribute }) =>
      attribute.includes('emailTemplate/')
    );
    if (hasError) {
      return this.intl.t('recurring-invoices.new.form.validation.email-details.empty');
    }
  }

  get emailDetailsRecipient() {
    return (this.model.subscription.emailTemplate?.sendTo ?? []).join(', ');
  }

  get shouldDisplayEinvoicing() {
    return this.model.subscription?.isEinvoice;
  }

  @action editEmailDetails() {
    this.modals.open(
      'invoice-subscriptions/form/email-details/modal',
      {
        subscription: this.model.subscription,
        settings: this.model.settings,
        isFullScreenModal: true,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  }

  @action
  onSelectMandate(mandate) {
    this.model.subscription.directDebitCollectionMandate = mandate;
  }

  abortCreationTask = dropTask(async (close, { origin }) => {
    this.segment.track('recurring-invoices_creation_canceled', { origin });
    this.router.transitionTo(this.origin ? this.origin : 'invoice-subscriptions.index');
    await close();
  });

  @action showClosePopup(origin) {
    return this.modals.open('popup/confirmation', {
      title: this.intl.t('recurring-invoices.new.form.abort-popup.title'),
      description: this.intl.t('recurring-invoices.new.form.abort-popup.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.leave'),
      confirmTask: this.abortCreationTask,
      origin,
    });
  }

  onCustomerUpdate = dropTask(async customer => {
    let emailTemplate = this.model.subscription.emailTemplate;
    this.model.subscription.emailTemplate = {
      ...emailTemplate,
      sendTo: customer.email ? [customer.email] : ['-'],
      emailTitle:
        emailTemplate?.emailTitle ??
        this.intl.t('recurring-invoices.new.modal.email-details.subject-placeholder', {
          language: customer.locale,
        }),
      emailBody:
        emailTemplate?.emailBody ??
        this.intl.t('recurring-invoices.new.modal.email-details.message-placeholder', {
          language: customer.locale,
        }),
    };

    if (variation('feature--boolean-scale-sdd-rcur-invoice')) {
      let customerMandates = await this.loadMandatesTask.perform(customer.id);

      if (customerMandates.length) {
        this.model.subscription.directDebitCollectionMandate = customerMandates[0];
      }
    }
  });

  openConfirmSaveModal = dropTask(async () => {
    this.segment.track('recurring-invoice_creation_submitted');
    await this.modals.open(
      'receivable-invoices/confirm-creation-modal',
      {
        title: this.intl.t('recurring-invoices.new.form.confirmation-popup.title'),
        description: this.intl.t('recurring-invoices.new.form.confirmation-popup.subtitle', {
          date: new Date(this.model.subscription.startDate).toLocaleDateString(
            this.intl.primaryLocale,
            {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
              timeZone: 'UTC',
            }
          ),
        }),
        confirm: this.intl.t('recurring-invoices.new.form.confirmation-popup.create-cta'),
        cancel: this.intl.t('recurring-invoices.new.form.confirmation-popup.cancel-cta'),
        confirmTask: this.saveTask,
        shouldDisplayEinvoicing: this.shouldDisplayEinvoicing,
        isSubscription: true,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  });

  saveTask = dropTask(async closeModal => {
    try {
      await this.model.subscription.save();
      // Remove locally stored items because backend return copies of those items with new ids
      this.model.subscription.clearItemsWithNoId();
      this.toastFlashMessages.toastSuccess(this.intl.t('recurring-invoices.toasts.success.create'));
      this.segment.track('recurring-invoices_creation_confirmed', {
        success: true,
        autoDebit: this.model.subscription.directDebitEnabled,
      });

      if (this.model.subscription.directDebitEnabled) {
        return this.router.transitionTo(
          'invoice-subscriptions.sdd.setup',
          this.model.subscription.id
        );
      }
      this.router.transitionTo('invoice-subscriptions.index');
    } catch (error) {
      this.segment.track('recurring-invoices_creation_confirmed', {
        success: false,
      });
      this.handleError(error);
    } finally {
      closeModal?.();
    }
  });

  loadMandatesTask = dropTask(async customerId => {
    try {
      this.isMandatesLoadingError = false;

      return await this.directDebitCollectionsManager.loadMandates(customerId);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.isMandatesLoadingError = true;
      this.model.subscription.directDebitEnabled = false;

      return [];
    }
  });

  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
    if (!ERROR_TOAST_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
    later(this, this.scrollToError, macroCondition(isTesting()) ? 0 : 200);
  }

  scrollToError() {
    scrollIntoView('[data-has-error]');
  }
}
