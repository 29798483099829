export default {
  "container": "qrZ",
  "content": "qrR",
  "logos": "qrQ",
  "logo": "qre",
  "animation": "qrB",
  "title": "qrE title-2 mb-32",
  "avatar": "qrd",
  "actions": "qrI mb-32",
  "footer": "qrt body-2"
};
