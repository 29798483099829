export default {
  "title": "quj title-3",
  "personal-profile-container": "quW",
  "personal-profile-section": "quu",
  "member-info-title": "ZSS",
  "kyc-complete-profile": "ZSc",
  "info-fields": "ZSq",
  "field": "ZSZ",
  "address-fields": "ZSR",
  "field-span": "ZSQ",
  "input": "ZSe",
  "cancel": "ZSB"
};
