export default {
  "guests": "qYr",
  "guests--upsell-screen": "qYb",
  "no-members": "qYy",
  "teams-header": "qYU",
  "empty": "qYj",
  "invite-text": "qYW",
  "body": "qYu",
  "members": "qhS",
  "members-list": "qhc",
  "members-title": "qhq caption-bold",
  "member": "qhZ",
  "details": "qhR"
};
