export default {
  "team": "qGa",
  "no-members": "qGH",
  "teams-header": "qGO",
  "header-search": "qGm",
  "search-bar": "qGp",
  "invite": "qGx body-2",
  "invite-text": "qGw",
  "empty-illustration": "qGN",
  "empty-title": "qGv title-3",
  "empty-description": "qGo body-2",
  "pagination-footer": "qGn",
  "filter-search": "qGi",
  "fullsize": "qGA",
  "tabs-container": "qGY",
  "tabs-separator": "qGh",
  "tooltip-wrapper": "qGf",
  "invite-cta": "qGK"
};
